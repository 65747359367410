

















import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeaderSub from "@/components/main-header-sub.vue";
import NavLeftSub from "@/components/nav-left-sub.vue";
import Jianyan from "@/components/duijie/jianyan/jianyan.vue";
@Component({
  components: {
    NavLeftSub,
    MainHeaderSub,
    Jianyan,
  },
})
export default class Name extends Vue {
  private get patient() {
    return this.$store.state.patient;
  }
}
